<template>
  <div>
    <div class="auth-wrapper auth-wrapper-reset-password auth-v1 px-2">
      <div class="auth-inner py-2">

        <!-- Forgot Password v1 -->
        <b-card class="mb-0">
          <b-link class="brand-logo">
            <b-img
              :src="logoUrl"
              alt="Payrite"
              style="width:150px"
            />
          </b-link>

          <b-card-title class="mb-1 text-center">
            Wachtwoord vergeten?
          </b-card-title>
          <b-card-text class="mb-2 text-center">
            Voer je e-mailadres in en we sturen je instructies om je wachtwoord opnieuw in te stellen
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-3"
              @submit.prevent="validationForm"
            >
              <!-- email -->
              <b-form-group
                label="E-mailadres"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                class="mt-3"
                variant="primary"
                block
                type="submit"
              >
                <b-spinner
                  small
                  class="mr-1"
                  v-if="spinner"
                />
                Verstuur reset-link
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon"/>
              terug naar login
            </b-link>
          </b-card-text>

        </b-card>
        <!-- /Forgot Password v1 -->
      </div>
    </div>
    <template>
      <p class="clearfix mb-0 login-footer">
      </p>
    </template>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BImg,
  BLink,
  BSpinner,
} from 'bootstrap-vue'
import { email, required } from '@validations'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import authenticationStoreModule from '@/views/pages/authentication/authenticationStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BImg,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      /* eslint-disable global-require */
      logoImg: require('@/assets/images/logo/payrite.png'),
      userEmail: '',
      // validation
      required,
      email,
      spinner: false,
    }
  },
  computed: {
    logoUrl() {
      return this.logoImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate()
        .then(success => {
          if (success) {
            this.spinner = true
            store.dispatch('auth-forgot-password/forgotPassword', { email: this.userEmail })
              .then(response => {
                this.spinner = false
                if (response.data.success) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Wachtwoord vergeten',
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                      text: 'U heeft een e-mail ontvangen, volg de instructies op in de mail.',
                    },
                  })
                  this.$router.push({ name: 'auth-login' })
                }
                if (!response.data.success) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Wachtwoord vergeten',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: response.data.message,
                    },
                  })
                }
              })
              .catch(() => {
                this.spinner = false
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Wachtwoord vergeten',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: 'Er is een fout opgetreden, probeer later het nog een keer.',
                  },
                })
              })
          }
        })
    },
  },
  setup() {
    const FORGOT_PASSWORD_APP_STORE_MODULE_NAME = 'auth-forgot-password'

    // Register module
    if (!store.hasModule(FORGOT_PASSWORD_APP_STORE_MODULE_NAME)) store.registerModule(FORGOT_PASSWORD_APP_STORE_MODULE_NAME, authenticationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORGOT_PASSWORD_APP_STORE_MODULE_NAME)) store.unregisterModule(FORGOT_PASSWORD_APP_STORE_MODULE_NAME)
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.auth-wrapper-reset-password {
  background: url("../../../assets/images/pages/lostpassword.jpg");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

[dir] .auth-wrapper.auth-v1 .auth-inner:before {
  background-image: none !important;
}
</style>
